import React from 'react';
import styled from 'styled-components';
import GenreBodyModel from '@models/genre-body';
import { useTranslation } from 'react-i18next';
import { SECTION_DISPLAY_TABLE } from '@constants/constant';
import { CardList } from '@components/CardList';

interface GenreSectionBodyProps {
  genre?: string;
  body: GenreBodyModel;
}

const GenreSectionBody = (props: GenreSectionBodyProps) => {
  const { t } = useTranslation();
  const genre: string = props.genre ?? '';

  return (
    <div className="section body">
      <div className="section-content">
        <GenreSectionBodyTitleStyle>{t(`${genre}_detail`)}</GenreSectionBodyTitleStyle>
        <GenreSectionBodyStyle>
          <CardList displayType={SECTION_DISPLAY_TABLE.HORIZONTAL_TILE_LIST} genre={genre} />
        </GenreSectionBodyStyle>
      </div>
    </div>
  );
};

export { GenreSectionBody };

const GenreSectionBodyTitleStyle = styled.h1`
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0px 16px;
  white-space: pre-wrap;
`;

const GenreSectionBodyStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
