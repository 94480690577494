import React from 'react';
import styled from 'styled-components';

const LoadingSpinner = () => {
  return (
    <LoadSpinnerDiv>
      <LoadingSpinnerStyle viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
      </LoadingSpinnerStyle>
    </LoadSpinnerDiv>
  );
};

export { LoadingSpinner };
const LoadSpinnerDiv = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
`;

const LoadingSpinnerStyle = styled.svg`
  z-index: 1;
  animation: rotate 2s linear infinite;
  display: flex;
  position: relative;
  top: 40%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #d1d1d1;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
