import React, { useRef } from 'react';
import GenreHeader from '@models/genre-header';
import GenreBody from '@models/genre-body';
import { FloatingUpButton } from '@components/FloatingUpButton';
import { ContentFill, Main } from '@layouts/PageLayout';
import { GenreSectionHeader } from '@components/Genre/GenreSectionHeader';
import { GenreSectionBody } from '@components/Genre/GenreSectionBody';

interface GenreSectionProps {
  header?: GenreHeader;
  body?: GenreBody;
  genre?: string;
}

const GenreSection = (props: GenreSectionProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ContentFill className="section" ref={ref}>
      {props.header && <GenreSectionHeader header={props.header} />}
      {props.body && <GenreSectionBody body={props.body} genre={props.genre} />}
      <FloatingUpButton scrollContainerRef={ref} />
    </ContentFill>
  );
};

export { GenreSection };
