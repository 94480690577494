import React from 'react';
import { navigate } from 'gatsby';
import BackIcon from '../assets/images/back.svg';
import { useLocation, WindowLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';

interface LocationState {
  previousPathname: string;
}

interface NavigationBarProps {
  genre: string;
}

const NavigationBar = (props: NavigationBarProps) => {
  const { t } = useTranslation();
  const location = useLocation() as WindowLocation<LocationState | null>;

  return (
    <nav>
      <div className="navigation-container">
        <div
          className="navigation-back-button"
          onClick={() => {
            if (location.state?.previousPathname) navigate(-1);
            else window.Realworld.close();
          }}
        >
          <img className="navigation-back-button-img" src={BackIcon.toString()} />
        </div>

        <div className="navigation-title">
          <header>{t(props.genre)}</header>
        </div>
      </div>
    </nav>
  );
};
export { NavigationBar };
