import React from 'react';
import styled from 'styled-components';
import GenreHeader from '@models/genre-header';
import ProjectModel from '@models/project';
import { SECTION_DISPLAY_TABLE } from '@constants/constant';
import { CardList } from '@components/CardList';

interface GenreSectionHeaderProps {
  header: GenreHeader;
}

const GenreSectionHeader = (props: GenreSectionHeaderProps) => {
  const title: string = props.header?.name;
  const headerProjects: ProjectModel[] = props.header?.projects;
  return (
    <div className="section header">
      <div className="section-content">
        <GenreSectionHeaderTitleStyle>{title}</GenreSectionHeaderTitleStyle>
        <CardList projects={headerProjects} displayType={SECTION_DISPLAY_TABLE.HORIZONTAL_SCROLL_LIST} />
      </div>
    </div>
  );
};

export { GenreSectionHeader };

const GenreSectionHeaderTitleStyle = styled.h1`
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0px 16px;
  white-space: pre-wrap;
`;
