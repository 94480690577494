import React from 'react';
import { PageProps } from 'gatsby';
import PageLayout from '@layouts/PageLayout';
import useGenreSection from '@hooks/useGenreSection';
import { NavigationBar } from '@components/NavigationBar';
import { GenreSection } from '@components/Genre/GenreSection';
import { LoadingSpinner } from '@components/LoadingSpinner';
import '@locales/i18n';

const GenrePage = ({ location }: PageProps) => {
  const genre = new URLSearchParams(location.search).get('genre') ?? undefined;
  const { genreSection, isGenreSectionLoading } = useGenreSection({ genre: genre });
  const genreHeader = genreSection?.header;
  const genreBody = genreSection?.body;

  return (
    <PageLayout>
      <NavigationBar genre={genre ? genre : ''} />
      {isGenreSectionLoading ? <LoadingSpinner /> : <GenreSection body={genreBody} header={genreHeader} genre={genre} />}
    </PageLayout>
  );
};

export default GenrePage;
